import { css } from "@emotion/core";
import {
  mediaquery,
  rem,
  scaleSpacing,
  colors,
  hexRgba,
  zIndexes,
} from "./theme";
import {
  NotoBody,
  NotoSmall,
  NotoBodySmall,
  NotoLinkSmall,
  OswaldH3,
  OswaldH4,
  OswaldH5,
} from "../src/components/Atoms/Typography";
import { flexButtonStyle } from "./buttons";

// code snippets

const sectionWidth = css`
  margin: 0 var(--external-gutter);

  ${mediaquery.sm(css`
    margin: 0 auto;
    width: calc(var(--col) * 12 - var(--gutter-size));
  `)}

  ${mediaquery.md(css`
    width: calc(var(--col) * 8 - var(--gutter-size));
  `)}

  ${mediaquery.l(css`
    width: calc(var(--col) * 7 - var(--gutter-size));
  `)}

  ${mediaquery.xxl(css`
    width: calc(var(--col) * 6 - var(--gutter-size));
  `)}
`;

const footerWidth = css`
  margin: 0 ${scaleSpacing(6)};
  width: calc(100% - ${scaleSpacing(12)});
`;

// Main styles
// overrides react-modal styles

const inplayerStyles = css`
  /* react modal */
  .ReactModal__Overlay:not(.mk2-modal):not(.mk2-modal-fullscreen):not(.mk2-modal-search) {
    background: ${hexRgba(colors.black, 0)} !important;
    color: ${colors.black} !important;
    transition: all 300ms ease-in-out;
    opacity: 1;

    &.ReactModal__Overlay--after-open {
      background: ${hexRgba(colors.black, 0.6)} !important;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }

    &.ReactModal__Overlay--before-close {
      background: ${hexRgba(colors.black, 0)} !important;
      opacity: 1;
    }

    .ReactModal__Content {
      position: fixed !important;
      box-shadow: none !important;
      --webkit-overflow-scrolling: touch;
      overflow: scroll !important;
      top: 0 !important;
      left: auto !important;
      right: 0 !important;
      bottom: 0 !important;
      margin: 0 !important;
      min-width: none !important;
      max-width: none !important;
      width: 100%;
      transform: translateX(99%);
      transition: all 300ms ease-in-out;
      background: ${colors.white} !important;
      border: none !important;
      border-radius: 0 !important;
      z-index: ${zIndexes.top};

      ${mediaquery.sm(css`
        width: calc(
          var(--col) * 14 - var(--gutter-size) + var(--external-gutter)
        );
      `)}
      ${mediaquery.md(css`
        width: calc(
          var(--col) * 10 - var(--gutter-size) + var(--external-gutter)
        );
      `)}
      ${mediaquery.xl(css`
        width: calc(
          var(--col) * 9 - var(--gutter-size) + var(--external-gutter)
        );
      `)}
      ${mediaquery.xxl(css`
        width: calc(
          var(--col) * 8 - var(--gutter-size) + var(--external-gutter)
        );
      `)}

      &.ReactModal__Content--after-open {
        transform: translateX(0%);
      }

      &.ReactModal__Content--before-close {
        transform: translateX(100%);
        opacity: 1;
      }
    }
  }

  /* InPlayer force hide content */

  .inplayer-paywall {
    div[class*="Account__HeadlineInfo"],
    header .member,
    .formelements.sublinks,
    #userdetails > strong,
    .formelements i,
    [class*="PaymentHeader__StyledIcon"],
    [class*="PaymentVoucher__VoucherIcon"],
    [class*="TabsWrapper"] svg,
    i[class*="FieldIcon"],
    div[class*="Input__IconContainer"],
    div[class*="Input__Bar"],
    .formelements .date-tooltip,
    div[class*="AccountFormElement"] div[class*="CheckboxWrapper"],
    strong[class*="Account__StyledTitle"],
    [class*="shared__FieldErrorWrapper"] > label,
    [class*="CardPayment__FieldsWrapper"] [class*="CardPayment__Label"],
    div[class*="SharedFormElements"]
      [class*="shared__FieldErrorWrapper"]
      label
      + div:before {
      display: none;
    }

    div[class*="SharedFormElements"]
      [class*="shared__FieldErrorWrapper"]:nth-of-type(n + 14) {
      display: none;
    }

    /* newsletter / Informations -  hijack acccount panel */

    .newsFormFieldsOnly && {
      div[class*="AccountFormElement"] [class*="DatePickerWrapper"],
      div[class*="AccountFormElement"] [class*="DropdownContainer"],
      div[class*="AccountFormElement"] [class*="DropdownLabel"],
      div[class*="AccountFormElement"] [class*="Input__InputContainer"],
      [class*="shared__FieldErrorWrapper"] > label ~ div,
      div[class*="Account__TabsWrapper"] {
        display: none;
      }
    }

    .newsFormFieldsOnly
      &&
      div[class*="AccountFormElement"]
      [class*="CheckboxWrapper"] {
      display: block;
      margin: ${scaleSpacing(10)} 0;
    }

    .newsFormFieldsOnly
      &&
      div[class*="AccountFormElement"]
      [class*="ButtonHolder"] {
      margin-top: ${scaleSpacing(20)};
    }

    .newsFormFieldsOnly && a[class*="shared__HeadlineAnchor"],
    .infosFormFieldsOnly && a[class*="shared__HeadlineAnchor"] {
      font-size: 0;
      padding: 0;
      &:after {
        content: "";

        ${OswaldH4};
        padding: 0;
        margin: 0;
        position: relative;
        color: ${colors.darkgrey1};
        font-weight: 200;
        border: none;
        text-transform: none;

        &.active {
          color: ${colors.black};
          font-weight: 300;
        }
      }
    }

    .newsFormFieldsOnly && a[class*="shared__HeadlineAnchor"] {
      &:after {
        content: "Mes newsletters";
      }
    }
    .infosFormFieldsOnly && a[class*="shared__HeadlineAnchor"] {
      &:after {
        content: "Mes informations";
      }
    }
    .newsFormFieldsOnly && {
      div[class*="SharedFormElements"]
        div[class*="FieldErrorWrapper"]:nth-of-type(3),
      div[class*="SharedFormElements"]
        [class*="FieldErrorWrapper"]:nth-of-type(n + 8),
      div[class*="SharedFormElements"]
        [class*="shared__FieldErrorWrapper"]
        > label
        ~ div:not([class*="CustomFieldError"]):not([class*="InputWrapper"]) {
        display: none;
      }
    }
    .infosFormFieldsOnly && {
      div[class*="SharedFormElements"]
        div[class*="FieldErrorWrapper"]:nth-of-type(3)
        div[class*="CheckboxWrapper"] {
        display: block;
      }
    }
  }

  /* InPlayer inner paywall content */

  .inplayer-paywall {
    width: auto;
    color: ${colors.black};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    pointer-events: all;
    background-color: ${colors.white};
    padding-top: ${scaleSpacing(14)};
    z-index: 9999;

    a {
      color: ${colors.black};
    }
    // common

    [class*="GridContainer"] {
      max-width: none;
      display: grid;
      height: auto;
      grid-auto-flow: row;
      grid-auto-rows: minmax(0, auto);
      grid-template-columns: repeat(1, 1fr);
      gap: ${scaleSpacing(6)} 0;
      padding: 0 0 ${scaleSpacing(6)} 0;
      [class*="GridContainer"] {
        padding: 0;
        gap: ${scaleSpacing(0)} 0;
      }
    }

    [class*="Typography__Heading"] {
      ${OswaldH5};
      text-align: center;
      margin: ${scaleSpacing(6)} auto;
      width: 100%;
    }

    ${mediaquery.md(css`
      [class*="Typography__Heading"] {
        ${OswaldH4};
      }
    `)}

    p[class*="Typography__Paragraph"] {
      ${NotoBody}
      color: ${colors.black};
      padding: 0;
      text-align: left;
      max-width: 100%;
      margin: 0;
      font-style: normal;
    }

    span[class*="shared__ItalicSpan"] {
      ${NotoBody}
      color: ${colors.mediumgrey1};
      padding: 0;
      font-style: normal;
    }

    div[class*="shared__Rows"] {
      width: 100%;
    }

    // Loader

    svg[class*="Loader"] {
      margin: 0 auto ${rem(40)};
      left: auto;
      right: auto;
    }
    // header

    header,
    div[class*="TabContainer"] {
      width: 100%;
      margin: 0;
      padding: 0;
      position: relative;
      box-shadow: none;
    }

    div[class*="BrandHeader"],
    #brandheader {
      background: ${colors.black} !important;
      border-radius: 0 !important;
      padding: 0;
      position: absolute;
      top: 0;
      border-bottom: none;
      height: ${rem(56)};
      z-index: ${zIndexes.top};
      width: 100%;
    }

    div[class*="Auth__NavigationWrapper"],
    #brandheader + div {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin: 0;
    }

    a[class*="CloseButton"],
    a#close {
      position: absolute;
      right: ${scaleSpacing(4)};
      top: ${scaleSpacing(4)};

      width: ${rem(22)};
      height: ${rem(22)};
      padding: ${scaleSpacing(3)} 0;
      i {
        background: white;
        width: ${rem(22)};
        height: ${rem(1)};
        font-weight: 600;
      }
    }

    // headline

    div[class*="shared__Headline"],
    div[class*="TabContainer"] {
      margin-top: ${scaleSpacing(10)};
      margin-bottom: ${scaleSpacing(10)};
    }
    div[class*="shared__Headline"] div[class*="TabContainer"] {
      margin: 0;
    }

    div[class*="TabContainer"],
    div[class*="shared__Headline"],
    /* div[class*="PaymentTypeSection"], */
    .headline {
      position: relative;
      max-width: none;
      /*  hide "/" separator */
      font-size: 0;
      display: flex;
      justify-content: center;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      .currentpage {
        border-bottom: none !important;
      }

      &:after {
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        bottom: ${rem(2)};
        left: 0;
        background-color: ${hexRgba(colors.mediumgrey3, 0.15)};
        display: block;
      }

      label[class*="PaymentHeader__PaymentLabel"],
      label,
      span,
      a {
        ${OswaldH5};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        cursor: pointer;
        padding: 0 ${scaleSpacing(2)} ${scaleSpacing(3.5)};
        margin: 0;
        position: relative;
        color: ${colors.darkgrey1};
        font-weight: 200;
        text-transform: lowercase;
        border: none;
        display: block;
        &:first-letter {
          text-transform: uppercase;
        }
        &.active {
          color: ${colors.black};
          font-weight: 300;
        }
      }

      a[class*="kskhuU"],
      a[class*="dUtAsk"],
      .ipx-headlogin,
      .ipx-headregister {
        border: none !important;

        &:after {
          content: "";
          height: ${rem(5)};
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          margin-top: ${scaleSpacing(3.5)};
          background-color: transparent;
          border-radius: ${scaleSpacing(2)};
          display: block;
        }
        &.active:after {
          background-color: ${colors.curiosity};
        }
        &:not(:last-of-type) {
          margin-right: ${scaleSpacing(9)};
        }
      }

      a[class*="dUtAsk"] {
        &:after {
          background-color: ${colors.curiosity};
        }
      }
    }

    ${mediaquery.md(css`
      div[class*="TabContainer"],
      div[class*="shared__Headline"],
      .headline {
        label,
        span,
        a {
          ${OswaldH4};
        }
      }
    `)}

    // progress

    [class*="Footer__ProgressBarWrapper"] {
      height: ${rem(5)};
      display: flex;
      justify-content: space-between;
      ${footerWidth};
      margin-top: auto;
      z-index: ${zIndexes.bottom};

      > div {
        flex: 1;
        border-radius: ${scaleSpacing(2)};
        height: ${rem(5)};
        background-color: ${colors.lightgrey2} !important;
        &:first-of-type {
          background-color: ${colors.curiosity} !important;
        }
        &:not(:first-of-type) {
          margin-left: ${scaleSpacing(2)};
        }
      }
    }

    // footer

    [class*="Footer__FooterContainer"] {
      background: ${colors.white};
      ${footerWidth}
      margin-top: -${rem(3)};
      padding: ${rem(3)} 0 0 0;
      border-bottom: 1px solid ${colors.lightgrey};
      position: relative;

      label,
      a {
        ${NotoBody};
        color: ${colors.black};
        padding-left: 0;
        text-transform: lowercase;
        &:first-letter {
          text-transform: uppercase;
        }
      }

      div[class*="Footer__LanguagesWrapper"] {
        position: relative;
        display: none;
      }
      div[class*="Footer__FooterDropdownMenu"] > div {
        padding: ${scaleSpacing(6)};
        a {
          ${NotoBodySmall};
        }
      }

      .footerlinks {
        flex: 1;
        width: auto;
      }

      #inplayer-branded {
        ${NotoBodySmall};
        color: ${colors.darkgrey3};
        a,
        svg {
          width: ${scaleSpacing(6)};
          height: ${scaleSpacing(6)};
        }
      }
    }

    /* main */

    section {
      background-color: ${colors.white};
      max-height: none;
      overflow-y: visible;
      ${sectionWidth}
      border-bottom: none;
      padding: 0;
      margin-bottom: 0;
      &:last-of-type {
        flex: 1;
      }
    }
    div[class*="BrandHeader"] + section,
    section + section {
      margin-top: ${scaleSpacing(6)};
      margin-bottom: ${scaleSpacing(12)};
    }

    ${mediaquery.md(css`
      section {
        padding: 0 var(--gutter-size);
      }
    `)}

    // social

    section[class*="SocialWrapper"] {
      box-shadow: none;

      div[class*="SocialOptions"] {
        position: relative;
        width: 100%;
        max-width: none;
        min-width: unset;
        margin: 0 auto;
        padding-bottom: ${scaleSpacing(12)};

        &:after {
          content: "ou";
          ${NotoBody};
          color: ${colors.black};
          width: ${rem(20)};
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 0 ${scaleSpacing(4)};
          background-color: ${colors.white};
          display: block;
          margin: auto;
          display: flex;
          justify-content: center;
        }
        &:before {
          content: "";
          height: ${rem(3)};
          width: 100%;
          position: absolute;
          bottom: ${rem(10)};
          left: 0;
          background-color: ${hexRgba(colors.mediumgrey3, 0.15)};
          display: block;
          border-radius: ${rem(8)};
        }
        div[class*="Tooltip__Container"] {
          width: 100%;
        }
        a {
          border-radius: ${rem(90)};
          height: ${rem(56)};
          border: 1px solid ${colors.black};
          background: ${colors.white} !important;
          display: flex;
          justify-content: center;
          align-items: center;

          span[class*="SocialButton__Label"] {
            color: ${colors.black};
            ${NotoBody};
          }

          &:not(:last-of-type) {
            margin-bottom: ${scaleSpacing(4)};
          }
          span[class*="SocialButton__Icon"] {
            width: ${rem(22)};
            height: ${rem(28)};
            margin: 0;
          }

          svg[color="#2980b9"] {
            color: ${colors.facebook};
          }
          svg {
            font-size: inherit;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    /* forms */

    div[class*="Register__TermsWrapper"] ~ div[class*="shared__ButtonHolder"] {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      &:after {
        ${NotoSmall};
        font-weight: 400;
        color: ${colors.darkgrey3};
        padding: ${scaleSpacing(6)} 0;
        content: "Les informations collectées ci-dessus nous sont nécessaires pour la création de votre compte et la gestion de vos commandes. Vous pouvez les modifier par la suite en accédant à l’interface utilisateur de votre compte personnel. Ces données sont uniquement destinées à nos équipes et pour les finalités susvisées. Si vous décidez de vous abonnez à l’une de nos newsletters, votre adresse e-mail sera utilisée par MK2 afin de vous envoyer la newsletter correspondante. Si vous ne souhaitez plus la recevoir, vous pouvez vous désabonner à tout moment en utilisant le lien de désinscription situé au bas de nos emails. Pour exercer les droits dont vous disposez conformément à la règlement RGPD, vous pouvez nous contacter à l’adresse email dpo@mk2.com. En savoir plus sur la gestion de vos données personnelles et sur vos droits : https://www.mk2.com/politique-confidentialite";
      }
    }

    div[class*="shared__ContainerWidth"] {
      padding: 0;
      margin: 0;
      width: 100%;
    }

    section div[class*="SharedFormElements"] {
      margin: 0;
      width: 100%;
    }

    div[class*="SharedFormElements"] {
      position: relative;
      padding: 0;
      margin: 0;
      width: 100%;
      max-width: none;
      display: flex;
      flex-direction: column;
      ${sectionWidth}

      /* fields cntainer */

      div[class*="FieldErrorWrapper"] {
        margin: 0;
        position: relative;
        line-height: 0;
      }

      div[class*="shared__InputHolder"] {
        position: relative;
        margin: 0;
      }

      div[class*="Input__InputContainer"] {
        position: relative;
        margin: 0;
        max-height: none;
        overflow: visible;
        display: flex;
        flex-direction: column;
      }
      a {
        font-weight: normal;
        text-decoration: underline;
      }

      /* inputs */

      div[class*="Input__InputWrapper"] {
        width: 100%;
        margin: 0;
      }

      input:not([type="checkbox"]):not([type="radio"]) {
        width: 100%;
        height: ${scaleSpacing(14)};
        padding: 0 ${scaleSpacing(6)};
        margin: 0 0 ${scaleSpacing(2)};
        border-radius: ${scaleSpacing(7)};
        border: none;
        outline: none;
        ${NotoBody};
        color: ${colors.mediumgrey2};
        border: 1px solid ${hexRgba(colors.mediumgrey2, 1)} !important;

        &:active,
        &:focus {
          border: 1px solid ${hexRgba(colors.curiosity, 1)} !important;
        }
        &.invalid {
          border: 1px solid ${hexRgba(colors.error, 1)} !important;
        }
        &.invalid ~ label {
          color: ${colors.mediumgrey1};
        }

        &:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          box-shadow: 0 0 0 ${rem(30)} white inset !important;
          -webkit-text-fill-color: ${colors.mediumgrey2} !important;
        }
      }

      /* label */

      label {
        ${NotoBody};
        height: ${scaleSpacing(14)};
        line-height: ${scaleSpacing(14)} !important;
        padding: 0;
        margin: 0;
        max-width: 100%;
        transform: none;
        color: ${colors.mediumgrey2} !important;
      }

      input:focus ~ label {
        color: ${colors.mediumgrey2};
        transform: none;
      }

      input[type="text"] ~ label,
      input[type="password"] ~ label,
      input[type="email"] ~ label {
        position: absolute;
        top: 0px;
        left: ${rem(28)};
      }
      input[value] ~ label {
        display: none;
      }
      input[value=""] ~ label {
        display: block;
      }

      [class*="Input__PasswordEyeWrapper"] {
        right: ${rem(12)};
        svg {
          width: ${rem(20)};
          height: ${rem(20)};
        }
      }
      /* radios */

      [class*="shared__FieldErrorWrapper"]
        > label
        ~ div:not([class*="CustomFieldError"]):not([class*="InputWrapper"]) {
        width: 33%;
        margin: 0;
        display: inline-grid;
        position: relative;
        label {
          display: flex;
          flex-direction: row;
        }
        span {
          width: auto;
        }
      }

      [class*="shared__FieldErrorWrapper"]
        > label
        ~ div
        div[class*="RadioButton"]
        + div[class*="CustomFieldError__ErrorMessageWrapper"],
      [class*="shared__FieldErrorWrapper"]
        > label
        ~ div
        div[class*="RadioButton"]
        + div[class*="CustomFieldError__ErrorMessageWrapper"]:before {
        display: none;
      }

      [class*="shared__FieldErrorWrapper"]
        > label
        ~ div:first-of-type
        div[class*="RadioButton"]
        + div[class*="CustomFieldError__ErrorMessageWrapper"],
      [class*="shared__FieldErrorWrapper"] {
        display: block;
      }

      [class*="shared__FieldErrorWrapper"]
        > label
        ~ div
        div[class*="CustomFieldError__ErrorMessageWrapper"] {
        position: relative;
        left: 0;
        margin-top: 0px;
      }

      /* checkbox */

      div[class*="CheckboxWrapper"] {
        margin: ${scaleSpacing(2)} ${scaleSpacing(3)} ${scaleSpacing(6)};

        label {
          padding: 0 0 0 ${scaleSpacing(8)};
          ${NotoBodySmall}
          line-height: 20px !important;
          height: auto !important;
          color: ${colors.black} !important;
          display: flex;

          &:before {
            margin-top: 3px;
            border: 1px solid ${colors.black};
          }

          &:after {
            margin-top: 2px;
            border-bottom: 2px solid ${colors.black};
            border-left: 2px solid ${colors.black};
            transition: none;
          }
        }

        & > input:not(:checked) + label:hover::after {
          border-color: ${colors.mediumgrey3};
        }

        & > input:not(:checked) + label:hover::before {
          background-color: ${colors.white};
        }

        & > input:checked + label:hover::before {
          background: transparent;
        }
      }

      /* select */

      label + select {
        width: 100%;
        position: relative;
        height: ${scaleSpacing(14)};
        padding: 0 ${scaleSpacing(6)};
        margin: 0 0 ${scaleSpacing(2)};
        border-radius: ${scaleSpacing(7)};
        border: none;
        outline: none;
        ${NotoBody};
        background: ${colors.white};
        border: 1px solid ${hexRgba(colors.mediumgrey2, 1)} !important;
        color: ${colors.white};
      }

      select:hover,
      select option {
        color: #fff;
      }

      label[class*="DatePickerLabel"],
      label[class*="DropdownLabel"] {
        position: absolute;
        top: 0;
        width: auto;
        pointer-events: none;
        z-index: 1;
        left: 24px;
      }

      .cOqDjX ~ label {
        display: none;
      }
      .cOqDjX ~ select {
        color: ${colors.mediumgrey2};
      }
      /*Added for browser compatibility*/
      [hidden] {
        display: none;
      }

      /* datepicker */

      div[class*="PaywallDatePicker"] {
        margin: 0;
      }
      label[class*="DatePickerLabel"] + span {
        width: 30px;
      }

      .SingleDatePickerInput {
        border: none;
      }
      .DateInput_input {
        text-align: right;
        color: #222;
        font-size: ${rem(14)} !important;
      }
      .DayPickerInput {
        padding: 0;
        border: none;
        margin-top: 0;
        position: relative;
        width: 100%;
      }

      /* error */

      div[class*="ErrorMessageWrapper"],
      .validation-error,
      .select-validation-error {
        ${NotoBodySmall};
        text-align: left !important;
        color: ${colors.error} !important;
        padding: 0 ${scaleSpacing(6)} ${scaleSpacing(4)};
        white-space: nowrap;
        &:before {
          content: "";
          width: ${scaleSpacing(6)};
          height: ${scaleSpacing(6)};
          position: absolute;
          width: calc(100% - 2px);
          height: ${rem(54)};
          position: absolute;
          border: 1px solid ${colors.error};
          top: 0;
          right: 0px;
          border-radius: ${rem(30)};
          z-index: 5;
          pointer-events: none;
        }
      }

      /* buttons */

      &.cardpayment button.inplayer-button:not(.gdpr) {
        margin-top: ${scaleSpacing(6)};
      }

      div[class*="shared__ButtonHolder"] {
        margin: ${scaleSpacing(8)} 0 ${scaleSpacing(12)};
        a {
          position: absolute;
          right: 0;
          left: auto;
          bottom: -${scaleSpacing(10)};
          ${NotoLinkSmall};
          text-decoration: none;
          text-transform: lowercase;
          &:first-letter {
            text-transform: uppercase;
          }
        }
      }

      div[class*="Input__ButtonHolder"] {
        display: flex;
        width: 100% !important;
        max-height: 100% !important;
        justify-content: center;
        flex-direction: column;
      }

      .pswrfield .buttonholder {
        align-items: flex-end;
        padding-bottom: ${scaleSpacing(6)};
      }

      button {
        ${flexButtonStyle}
        height: ${rem(48)};
        width: 100% !important;
        max-width: 100% !important;
        padding: 0 ${scaleSpacing(8)};
        border-radius: ${rem(90)};
        border: none !important;
        background: ${colors.black} !important;
        color: ${colors.white} !important;
        cursor: pointer;

        &:hover {
          text-decoration-line: none;
        }
      }

      /* passwordreset */

      .passwordreset ~ .inplayer-buttonholder {
        margin-bottom: ${scaleSpacing(5)};
      }

      [class*="ForgotPasswordLabel"] {
        height: auto;
        float: right;
        margin: 0;
        ${NotoLinkSmall};
        line-height: 12px !important;
        color: ${colors.black} !important;
        text-decoration: underline;

        label {
          text-decoration-line: underline;
          color: ${colors.black};
          padding: 0;
        }
      }
      &.lorsND {
        /* ${sectionWidth} */
      }

      /* Payment */

      div[class*="CardPayment__Container"],
      div[class*="CardPayment__FieldsWrapper"] {
        margin: 0;
        [class*="CardPayment__StyledFieldWrapper"] {
          position: relative;
        }
        [class*="Input__InputWrapper"] {
          width: 100%;
          margin: 0;
          display: block;
          position: relative;
        }
        [class*="Input__InputWrapper"] {
          width: 100%;
          margin: 0;
          display: block;
          position: relative;
        }
      }
      [class*="PaymentInfo__InfoHolder"] {
        ${NotoBody};
        margin: 0;
        &:last-of-type {
          margin-bottom: ${rem(30)};
        }
      }
      [class*="PaymentInfo__InfoHolder"]:first-of-type {
        margin-top: ${scaleSpacing(8)};
      }
      .rccs {
        & > div {
          display: none;
        }
      }

      [class*="PaymentVoucher"] + form {
        ${NotoBody};
        margin: 0;
        &:last-of-type {
          margin-bottom: ${scaleSpacing(16)};
        }
        &:after {
          ${NotoBodySmall};
          content: "Les informations collectées ci-dessus nous sont nécessaires pour le traitement de votre commande. Elles sont uniquement destinées à nos équipe pour traiter votre commande et vous fournir le service demandé. Pour exercer les droits dont vous disposez conformément à la règlement RGPD, vous pouvez nous contacter à l’adresse email dpo@mk2.com En savoir plus sur la gestion de vos données personnelles et sur vos droits : https://www.mk2.com/politique-confidentialite";
          color: #000;
        }
      }

      &.cardpayment {
        .inplayer-button {
          margin-top: ${scaleSpacing(6)};
        }
        .labelholder {
          max-height: none !important;
          .half {
            width: 49%;
            display: flex;
            flex-direction: column;
          }
        }
      }

      /* voucher */

      [class*="PaymentVoucher__EnterVoucherHolder"] {
        transition: none;
      }

      [class*="PaymentVoucher__VoucherHolder"] {
        height: auto;
        min-height: 64px;
        margin: 20px 0;

        [class*="PaymentVoucher__AddVoucherLabelHolder"] {
          position: absolute;
          right: 10px;
          top: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          min-width: 200px;
          svg {
            font-size: 1.2rem;
          }
        }

        label {
          ${NotoBody};
          color: ${colors.black};
          height: auto;
          display: flex;
          transition: none;
        }

        #voucher[value]:focus ~ label {
          top: 8px;
        }
      }
    }

    [class*="Account__TabsWrapper"] {
      margin: ${scaleSpacing(6)} 0 ${scaleSpacing(10)};
      width: 100%;

      [class*="TabsWrapper"] {
        border: none;
        display: flex;
        justify-content: space-around;
        > div span {
          ${NotoLinkSmall}
          text-decoration: underline;
          background-color: transparent;
          color: ${colors.black};
          max-width: ${scaleSpacing(25)};
          padding: 0;
          i {
            display: none;
          }
        }
      }
    }

    /* transactions & subscriptions table  */

    [class*="HistoryTable"] + [class*="Typography__Paragraph"] {
      margin: ${scaleSpacing(10)} var(--external-gutter) 0;
    }

    [class*="Subscriptions__Container"],
    [class*="History__Wrapper"] {
      width: auto;
      display: flex;
      flex-direction: column;
      margin-top: ${scaleSpacing(8)};
      h4,
      h5 {
        ${NotoBody};
        color: ${colors.black};
        padding: 0;
      }
      h4,
      h5,
      .type {
        width: 100%;
      }

      [class*="History__Type"] {
        width: 100%;

        &:after {
          content: "";
          position: absolute;
          top: ${scaleSpacing(6)};
          right: ${scaleSpacing(6)};
          color: ${colors.black};
          width: 7px;
          height: 7px;
          border: solid black;
          border-width: 0 1px 1px 0;
          display: inline-block;
          padding: 1px;
          transform: rotate(45deg);
        }
      }
      select[class*="DropdownContainer"],
      [class*="History__Type"] select {
        position: relative;
        width: 100%;
        height: ${scaleSpacing(12)};
        padding: 0 ${scaleSpacing(6)};
        margin: ${scaleSpacing(2)} 0 ${scaleSpacing(2)};
        border-radius: ${scaleSpacing(7)};
        border: none;
        outline: none;
        ${NotoBody};
        color: ${colors.black};
        text-transform: capitalize;
        background: ${colors.white};
        border: 1px solid ${hexRgba(colors.mediumgrey2, 1)} !important;
      }

      .container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0;
        :after {
          content: "\\203A";
          width: ${scaleSpacing(7)};
          height: ${scaleSpacing(7)};
          position: absolute;
          right: ${scaleSpacing(4)};
          color: black;
          font-size: ${scaleSpacing(7)};
          transform: rotate(90deg);
          top: ${scaleSpacing(16)};
        }
      }
    }

    /* GDPR */

    .buttonholder-gdpr,
    [class*="shared__ButtonsWrapper"] {
      width: 100% !important;
      margin: 0;
      align-items: center;
      padding-bottom: ${scaleSpacing(6)};
      border: none;
      padding: ${scaleSpacing(6)} 0 0 0;
      a {
        position: absolute;
        right: ${rem(10)};
        left: auto;
        top: ${rem(64)};
        bottom: 0;
        ${NotoLinkSmall};
        text-transform: lowercase;
        &:first-letter {
          text-transform: uppercase;
        }
      }

      button {
        ${flexButtonStyle}
        height: ${rem(48)};
        width: 100% !important;
        padding: 0 ${scaleSpacing(8)};
        border-radius: ${rem(90)};
        border: none !important;
        background: ${colors.black} !important;
        color: ${colors.white} !important;
        text-transform: capitalize;
        cursor: pointer;
        &:hover {
          text-decoration-line: none;
        }
      }
    }

    // payment options

    section[class*="PaymentTypeSection"] {
      display: flex;
      width: 100%;
      max-width: 100%;
      justify-content: center;
      flex-direction: row;
      margin: ${scaleSpacing(8)} 0 0;
      padding: 0;
      position: relative;

      &:after {
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        bottom: ${rem(2)};
        left: 0;
        background-color: ${hexRgba(colors.mediumgrey3, 0.15)};
        display: block;
      }

      label {
        border: none;
        width: auto;
        padding: 0 ${scaleSpacing(2)};
        margin: 0;
        position: relative;
        color: ${colors.darkgrey1};
        font-weight: 200;
        background: none;
        text-transform: lowercase;

        &:first-letter {
          text-transform: uppercase;
        }
        &.active {
          color: ${colors.black};
          font-weight: 300;
        }

        &[style*="background"] {
          i {
            opacity: 1;
          }
          h5:after,
          i.paypal:after {
            background-color: ${colors.curiosity};
          }
        }

        i.paypal {
          height: ${rem(54)};
          width: ${rem(120)};
          background-position: center ${rem(10)};
          background-size: c auto;
        }

        h5,
        i.paypal {
          ${OswaldH5};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          cursor: pointer;
          padding: 0 ${scaleSpacing(2)} ${scaleSpacing(3.5)};
          margin: 0;
          position: relative;
          color: ${colors.darkgrey1};
          font-weight: 200;
          text-transform: lowercase;
          border: none !important;

          &:not(:last-of-type) {
            margin-right: ${scaleSpacing(9)};
          }

          &:after {
            content: "";
            height: ${rem(5)};
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            margin-top: ${scaleSpacing(3.5)};
            background-color: transparent;
            border-radius: ${scaleSpacing(2)};
            display: block;
          }
          &:first-letter {
            text-transform: uppercase;
          }
          &.active {
            color: ${colors.black};
            font-weight: 300;
          }
        }
      }
    }

    ${mediaquery.md(css`
      section[class*="PaymentTypeSection"] label h5 {
        ${OswaldH4}
      }
    `)}

    /* Success screen */

    [class*="PaymentProcessor__Headline"] {
      display: flex;
      flex-direction: column;
      /* hide "/" separators */
      font-size: 0;
      text-transform: none;
      &::before {
        content: "Merci";
        color: ${colors.black};
        ${OswaldH3};
        padding-bottom: ${scaleSpacing(6)};
      }
    }

    [class*="PaymentProcessor__Spinner"] {
      display: none;
    }

    [class*="PaymentVipVoucher__Wrapper"] {
      ${NotoBody};
      text-align: center;
    }
  }
`;

export default inplayerStyles;
